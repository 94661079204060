@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

@font-face {
  font-family: 'SCHABO-Condensed';
  src: url('./assets/fonts/SCHABO-Condensed.woff2') format('woff2'),
       url('./assets/fonts/SCHABO-Condensed.woff') format('woff'),
       url('./assets/fonts/SCHABO-Condensed.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

html {
  width: 100%;
  margin: 0;
}

body {
  width: 100vw;
  margin: 0;
  height: 100vh;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch; /* smooth scroll for iOS */
}

.bg-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2); /* 20% black for slight grey overlay */
  z-index: 0;
}

.no-scroll {
  overflow: hidden !important;
  height: 100%;
}

/* ACT NOW */
/* Button act now - move this into a component? I'll place it here for now */
.act-now{
  background-image: radial-gradient(circle at 100% -30%, #A0D0E5, black 50%);
}


.button-act-now_circle-button-wrap{
  color:white;
}
.button-act-now_circle-button-wrap:after,
.button-act-now_circle-button-wrap:before{
  content:"";
  position:absolute;
  width:100%;
  height:100%;
  border:1px solid currentColor;
  border-radius:50%;
  z-index: 0;
}
.button-act-now_circle-button-wrap:before{
  width:340%;
  height:340%;
  top:-120%;
  left:-120%;
}
.button-act-now_circle-button-wrap:after{
  width:200%;
  height:200%;
  top:-50%;
  left:-50%;
}
.button-act-now_circle-button{

}
/* Hover */
.button-act-now:hover{
  background:white;
}
.button-act-now:hover .button-act-now_circle-button{
  color:white;
  background:black;
}
.button-act-now:hover .button-act-now_icon-accent{
  fill: rgb(226 229 111);
}
.button-act-now:hover .button-act-now_circle-button-wrap{
  color: rgb(226 229 111);
}

/* Act now sections */
.act-now_price_phone{
  height:50vh;
}

.act-now_links:after{
  content:"";
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index: -1;
  opacity: 0.5;
  background-image:radial-gradient(40.73% 40.71% at 0% 50%, rgba(62, 112, 218, 0.40) 0%, rgba(159, 193, 64, 0.00) 70%), 
  radial-gradient(10.88% 138.96% at 0% 50%, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.00) 80%);
}

.act-now-phone-type p:after{
  content:"";
  position:absolute;
  width:100%;
  height:1px;
  background:black;
  bottom:0;
  left:0;
  transform:translateX(-101%);
  transition:transform .25s ease-in-out;
}

.act-now-phone-type:hover p:after{
  transform:translateX(0%);
}


/*  FORM */

.form-select-item{
  
}
.form-select-item.is-active{
  color:rgb(62 112 218 / var(--tw-bg-opacity));
}

.form-select-item.is-active > .form-select-item_tick{
  display:flex;
}

.form-select-list div:last-child{
  border-bottom:0;
}

.button_select-phone:disabled{
  opacity: 0.5;
}

.button_select-phone:disabled:hover{
  cursor: not-allowed;
}

.button_select-phone:hover:enabled{
  background:white;
  color: rgb(62 112 218);
}




@media screen and (min-width: 768px){
  .br-mobile{
    display:none;
  }
  
}

/* LG media query 
  Let's discuss what you want to do with media queries
*/
@media screen and (min-width: 1024px){

  .act-now{
    background-image: radial-gradient( circle at 50% -70%, #A0D0E5, black 75%);
  }

  .act-now_links:after{
    content:"";
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index: -1;
    opacity: 0.5;
    background-image:radial-gradient(40.73% 40.71% at 0% 50%, rgba(62, 112, 218, 0.40) 0%, rgba(159, 193, 64, 0.00) 70%), 
    radial-gradient(60.88% 138.96% at 0% 50%, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.00) 80%);
  
    
}


/* XL media query 
  Let's discuss what you want to do with media queries
*/
@media screen and (min-width: 1280px){
  .act-now_price_amount{
    font-size:180px;
  }
  
  }
  
  .br-show-desktop{
    display:inline-block;
  }
}

/* apple */
.apple-device .apple\:pt-3\.5 {
  padding-top: 0 !important;
}


.apple-device .apple\:pt-3{
  padding-top: 0.2rem !important;
}

.apple-device .apple\:pt-2{
  padding-top: 0 !important;
}

.apple-device .apple\:pt-\[0\.75rem\] {
  padding-top: 0 !important;
}

.apple-device .apple\:pt-1{
  padding-top: 0 !important;
}

.apple-device .apple\:pt-\[0\.69rem\] {
  margin-top: -0.75rem !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

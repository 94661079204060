.modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    height: 90%;
    z-index: 1000;
    border-top-left-radius: 25px;  /* Added rounded corner */
    border-top-right-radius: 25px; /* Added rounded corner */
    max-width: 450px;
    margin: 0 auto;
    width: 100%;
    max-height: 720px;
  }

  .modal_top{
    z-index: 1001;
  }
  
  .modal-content {
    /* Add any desired styles for the modal content here */
    text-align: center;
    margin-top: 86px;
    padding: 0 30px;
    max-height: calc(100% - 86px);
    overflow-y: auto;
  }

  .telstraBtn span{
    display: flex;
    flex-direction: row;
  }

  .secondMod{
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 21px */
    letter-spacing: -0.42px;
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: space-between; /* space items apart evenly */
    align-items: center; 
    margin: 0 auto;
    margin-top: 30px;   
  }

  
  .close-btn {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .close-btn img {
    /* Adjust the size of your SVG close button if needed */
    width: 40px;
    height: 40px;
  }
  
  
t{
    color: #686868;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* space items apart evenly */
    align-items: center;  
}

t img{
    margin-left: 4px;
}
